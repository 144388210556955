import React, { useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import { domain } from '../../Variables/domain';

function Card() {
  const [tableData, setTableData] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const getSensor = async () => {
    try {
      const resp = await axios.get(`${domain}/card/getall`);
      setTableData(resp.data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getSensor();

    const interval = setInterval(() => {
      getSensor();
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full mx-4 md:mx-0 overflow-x-hidden md:w-9/12 flex flex-col">
      <div className="flex items-center justify-between mb-2">
        <h4 className="text-xl mb-2 font-semibold">ລາຍການບັດທັງໝົດ</h4>
      </div>
      <div className="w-full shadow-sm h-96 overflow-y-auto border rounded-sm">
        <table className="w-full text-center">
          <thead>
            <tr className="w-full">
              <th className="w-fit bg-[#26B899] p-4 text-white">ສະຖານະ</th>
              <th className="w-fit bg-[#26B899] p-4 text-white">ຊື່ບັດ</th>
              <th className="w-fit bg-[#26B899] p-4 text-white">ສ້າງເມື່ອ</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((val, key) => {
              return (
                <tr
                  role="row"
                  key={key}
                  style={{ borderBottom: '1px solid #e6e6e6' }}
                >
                  <td className="px-4 py-4 flex justify-center">
                    {val.card_status ? (
                      <div className="w-32 text-center bg-orange-100 border-[#ed5626] border-2 px-4 py-1 rounded-full">
                        ມີການໃຊ້ງານຢູ່
                      </div>
                    ) : (
                      <div className="w-32 text-center bg-green-100 border-green-600 border-2 px-4 py-1 rounded-full">
                        ວ່າງ
                      </div>
                    )}
                  </td>
                  <td className="px-4 py-4">{val.card_id}</td>
                  <td className="px-4 py-4">
                    {moment(val.rent_start).format('DD/MM/YYYY HH:mm:ss')}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Card;
