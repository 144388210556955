import React from 'react';
import moment from 'moment';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import { domain } from '../../Variables/domain';

function BillingOut() {
  const [tableData, setTableData] = React.useState([]);
  // console.log(tableData)

  const columns = [
    // { field: 'id', headerName: 'ID', width: 0, hidden: 'true'},
    {
      field: 'car_number',
      headerName: 'ປ້າຍລົດ',
      headerClassName: 'bg-[#ed5626] text-white',
      width: 160,
    },
    {
      field: 'card',
      headerName: 'ຊື່ບັດ',
      headerClassName: 'bg-[#ed5626] text-white',
      width: 150,
      valueGetter: function (params) {
        if (params.row.card?.card_id === undefined) {
          return 'ບໍ່ມີຂໍ້ມູນ';
        } else {
          return params.row.card.card_id;
        }
      },
    },
    {
      field: 'total_price',
      headerName: 'ລາຍຮັບທັງໝົດ',
      headerClassName: 'bg-[#ed5626] text-white',
      width: 200,
      valueFormatter: (params) => {
        if (params.value == null) {
          return 'ໍບໍ່ມີຂໍ້ມູນ';
        }
        return `${(Math.round(params.value * 100) / 100).toLocaleString()} ກີບ`;
      },
    },
    {
      field: 'rent_end',
      headerName: 'ເວລາອອກ',
      headerClassName: 'bg-[#ed5626] text-white',
      width: 200,
      valueGetter: (params) =>
        moment(params.row.rent_end).format('DD/MM/YYYY HH:mm:ss'),
    },
  ];

  const getSensor = async () => {
    try {
      const resp = await axios.get(`${domain}/bill/getRentOut`);
      setTableData(resp.data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getSensor();

    const interval = setInterval(() => {
      getSensor();
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full flex flex-col">
      <h4 className="text-xl mb-2 font-semibold">ສົ່ງລົດອອກ</h4>
      <div className="w-full h-96 shadow-sm">
        <DataGrid
          rows={tableData}
          columns={columns}
          loading={tableData.length > 0 ? false : true}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                ບໍ່ມິຂໍ້ມູນ
              </Stack>
            ),
          }}
          pageSize={5}
          sx={{ border: 'none', fontSize: '16px' }}
          rowsPerPageOptions={[5]}
          pagination
        />
      </div>
    </div>
  );
}

export default BillingOut;
