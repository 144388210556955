import React from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { api } from '../Variables/domain';

import { useToasts } from 'react-toast-notifications';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      <Link
        className="no-underline hover:text-blue-600"
        color="inherit"
        target="_blank"
        href="https://www.facebook.com/retechsole"
      >
        Powered by ReTech Sole Co.,Ltd
      </Link>
    </Typography>
  );
}

const theme = createTheme();

const apiUrl = `https://rent-car.twilightparadox.com/api/auth/login`;

const urlProfile = 'https://rent-car.twilightparadox.com/api/auth/me';

export default function SignIn() {
  const history = useHistory();
  const { addToast } = useToasts();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      const resp = await axios.post(apiUrl, {
        username: data.get('username'),
        password: data.get('password'),
      });

      const respProfile = await axios.get(urlProfile, {
        headers: {
          Authorization: 'Bearer ' + resp.data.access_token,
        },
      });

      if (respProfile.data.user.role === 'admin') {
        localStorage.setItem('token', JSON.stringify(resp.data));
        history.replace('/dashboard/overview');
        history.go(0);
      } else {
        addToast('ທ່ານບໍ່ມີສິດໃຊ້ງານໜ້ານີ້', {
          appearance: 'warning',
        });
      }

      //Save Detail User to LocalStorage
      localStorage.setItem('Profile', JSON.stringify(respProfile.data.user));
    } catch (error) {
      addToast(error.response.data.error.message, {
        appearance: 'error',
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Link href="/" className="rounded-sm my-3">
            <img
              className="w-28 md:w-48"
              src={require('../assets/img/logo_rent.png')}
              alt="logo"
            />
          </Link>
          <Typography className="font-semibold" component="h1" variant="h5">
            ລະບົບຕິດຕາມການສາກໄຟ
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="ຊື່ຜູ້ໃຊ້ງານ"
              type="username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="ລະຫັດຜ່ານ"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              className="bg-[#26B899] hover:bg-[#189a7e] w-full px-5 py-2 rounded text-white font-semibold"
              sx={{ mt: 3, mb: 2 }}
            >
              ເຂົ້າສູ່ລະບົບ
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 4, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
