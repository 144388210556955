import React, { useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import { domain } from '../../Variables/domain';

function User() {
  const [tableData, setTableData] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const getSensor = async () => {
    try {
      const resp = await axios.get(`${domain}/auth/getall`);
      setTableData(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getSensor();

    const interval = setInterval(() => {
      getSensor();
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full md:w-9/12 mx-4 md:mx-0 overflow-x-hidden flex flex-col">
      <div className="flex items-center justify-between mb-2">
        <h4 className="text-xl mb-2 font-semibold">ລາຍການພະນັກງານທັງໝົດ</h4>
      </div>
      <div className="w-full shadow-sm h-96 overflow-y-auto border rounded-sm mb-10 sm:mb-0">
        <table className="w-full text-center">
          <thead>
            <tr className="w-full">
              <th className="w-fit bg-[#ed5626] p-4 text-white">ຊື່ພະນັກງານ</th>
              <th className="w-fit bg-[#ed5626] p-4 text-white">
                ຊື່ເຂົ້າໃຊ້ງານ
              </th>
              <th className="w-fit bg-[#ed5626] p-4 text-white">ຕໍາແໜ່ງ</th>
              <th className="w-fit bg-[#ed5626] p-4 text-white">ສ້າງເມື່ອ</th>
              <th className="w-fit bg-[#ed5626] p-4 text-white">ແກ້ໄຂເມື່ອ</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((val, key) => {
              return (
                <tr
                  role="row"
                  key={key}
                  style={{ borderBottom: '1px solid #e6e6e6' }}
                >
                  <td className="px-4 py-4">{val.name}</td>
                  <td className="px-4 py-4">{val.username}</td>
                  <td className="px-4 py-4">{val.role}</td>
                  <td className="px-4 py-4">
                    {moment(val.rent_start).format('DD/MM/YYYY HH:mm:ss')}
                  </td>
                  <td className="px-4 py-4">
                    {moment(val.rent_end).format('DD/MM/YYYY HH:mm:ss')}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default User;
