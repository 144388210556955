import React from 'react';
import BillingIn from './BillingIn';
import BillingOut from './BillingOut';

const BillIndex = () => {
  return (
    <div className="w-full md:w-9/12 mx-4 md:mx-0 flex gap-x-5 flex-col md:flex-row gap-y-8 md:gap-y-0">
      <BillingIn />
      <BillingOut />
    </div>
  );
};

export default BillIndex;
