import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { domain } from '../../Variables/domain';

export default function ModalAddCompany() {
  const [open, setOpen] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { addToast } = useToasts();

  const addCompany = () => {
    axios
      .post(`${domain}/company/insert`, {
        company_name: companyName,
      })
      .then(() => {
        addToast('ເພີ່ມບໍລິສັດສໍາເລັດ', {
          appearance: 'success',
        });
      }).catch = (err) => {
      console.log(err);
      addToast(err, {
        appearance: 'error',
      });
    };
  };

  return (
    <div>
      <button
        className="bg-[#26B899] rounded-sm shadow-sm px-4 py-2 text-lg text-white"
        onClick={handleOpen}
      >
        ເພີ່ມບໍລິສັດ
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="modelCard">
          <label className="modalLabel">ຊື່ບໍລີສັດ</label>
          <input
            className="modalInput"
            type="text"
            placeholder="ໃສ່ຊື່ບໍລີສັດ"
            required
            onChange={(event) => {
              setCompanyName(event.target.value);
            }}
          />
          <button className="modalBtn" onClick={addCompany}>
            ເພີ່ມບໍລີສັດ
          </button>
        </form>
      </Modal>
    </div>
  );
}
