import React, { useState, useEffect } from 'react';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Navbar from '../../Components/Navbar';
import moment from 'moment';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CustomFooterTotalComponent } from '../../Components/Billing/customFooter';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DownloadIcon from '@mui/icons-material/Download';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import * as XLSX from 'xlsx';
import { useHistory } from 'react-router-dom';
import { domain } from '../../Variables/domain';

const OutcomePage = () => {
  const history = useHistory();

  const [startData, setStartData] = useState([]);
  const [total, setTotal] = useState(0);
  // const [start, setStart] = useState(moment().startOf('month'));
  // const [end, setEnd] = useState(moment().endOf('month'));
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [com, setCom] = useState('');
  const [company, setCompany] = useState([]);

  console.log(startData.length);

  const handleChangeDate = async (e) => {
    await getBilling();
    setStart(e[0]);
    setEnd(e[1]);
  };

  const columnsData = [
    // { field: 'id', headerName: 'ID', width: 0, hidden: 'true'},
    {
      field: 'company',
      headerName: 'ຊື່ບໍລິສັດ',
      headerClassName: 'bg-[#ed5626] text-white',
      width: 150,
      valueGetter: function (params) {
        if (params.row.company?.company_name === undefined) {
          return 'ບໍ່ມີຂໍ້ມູນ';
        } else {
          return params.row.company.company_name;
        }
      },
    },
    {
      field: 'car_number',
      headerName: 'ປ້າຍລົດ',
      headerClassName: 'bg-[#ed5626] text-white',
      width: 135,
    },
    {
      field: 'card',
      headerName: 'ຊື່ບັດ',
      headerClassName: 'bg-[#ed5626] text-white',
      width: 139,
      valueGetter: function (params) {
        if (params.row.card?.card_id === undefined) {
          return 'ບໍ່ມີຂໍ້ມູນ';
        } else {
          return params.row.card.card_id;
        }
      },
    },
    {
      field: 'duration_rent',
      headerName: 'ໄລຍະເວລາການເຊົ່າ',
      headerClassName: 'bg-[#ed5626] text-white',
      width: 135,
    },
    {
      field: 'total_price',
      type: 'number',
      headerName: 'ລວມເປັນເງິນ',
      headerClassName: 'bg-[#ed5626] text-white flex justify-start',
      width: 150,
      valueFormatter: (params) => {
        if (params.value === null) {
          return 'ໍບໍ່ມີຂໍ້ມູນ';
        }
        return `${(Math.round(params.value * 100) / 100).toLocaleString()} ກີບ`;
      },
    },
    {
      field: 'province',
      headerName: 'ແຂວງ',
      headerClassName: 'bg-[#ed5626] text-white',
      width: 200,
    },
    {
      field: 'phone_number',
      headerName: 'ເບີໂທຕິດຕໍ່',
      headerClassName: 'bg-[#ed5626] text-white',
      width: 150,
      valueGetter: function (params) {
        if (params.row.phone_number === '') {
          return 'ບໍ່ມີຂໍ້ມູນ';
        } else {
          return params.row.phone_number;
        }
      },
    },
    {
      field: 'rent_start',
      headerName: 'ເວລາເຂົ້າ',
      headerClassName: 'bg-[#ed5626] text-white',
      width: 180,
      valueGetter: (params) =>
        moment(params.row.rent_start).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      field: 'rent_end',
      headerName: 'ເວລາອອກ',
      headerClassName: 'bg-[#ed5626] text-white',
      width: 200,
      valueGetter: (params) =>
        moment(params.row.rent_end).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      field: 'action',
      headerName: 'ການຈັດການ',
      headerClassName: 'bg-[#ed5626] text-white',
      sortable: false,
      width: 180,
      renderCell: (params) => {
        const onClick = (e) => {
          DeleteBilling(params.row._id);
          // console.log(params.row._id)
        };

        return (
          <button
            className="bg-red-500 text-white mx-4 px-2 py-1 rounded-sm w-3/4"
            onClick={onClick}
          >
            ລົບໃບບິນ
          </button>
        );
      },
    },
  ];

  const rows = startData.map((row) => {
    if (row.card?.card_id === undefined) {
      return {
        ບໍລິສັດ: row.company?.company_name,
        ປ້າຍລົດ: row.car_number,
        ຊື່ບັດ: 'ບໍ່ມີຂໍ້ມູນ',
        // ປະເພດການຈ່າຍ: row.payment_type,
        ໄລຍະເວລາການເຊົ່າ: row.duration_rent,
        ລວມເປັນເງິນ: row.total_price,
        ແຂວງ: row.province,
        ເບີໂທຕິດຕໍ່: row.phone_number,
        ເວລາເຂົ້າ: moment(row.rent_start).format('DD/MM/YYYY HH:mm:ss'),
        ເວລາອອກ: moment(row.rent_end).format('DD/MM/YYYY HH:mm:ss'),
      };
    } else if (row.company?.company_name === undefined) {
      return {
        ບໍລິສັດ: 'ບໍ່ມີຂໍ້ມູນ',
        ປ້າຍລົດ: row.car_number,
        ຊື່ບັດ: row.card.card_id,
        // ປະເພດການຈ່າຍ: row.payment_type,
        ໄລຍະເວລາການເຊົ່າ: row.duration_rent,
        ລວມເປັນເງິນ: row.total_price,
        ແຂວງ: row.province,
        ເບີໂທຕິດຕໍ່: row.phone_number,
        ເວລາເຂົ້າ: moment(row.rent_start).format('DD/MM/YYYY HH:mm:ss'),
        ເວລາອອກ: moment(row.rent_end).format('DD/MM/YYYY HH:mm:ss'),
      };
    } else if (
      row.card?.card_id === undefined &&
      row.company?.company_name === undefined
    ) {
      return {
        ບໍລິສັດ: 'ບໍ່ມີຂໍ້ມູນ',
        ປ້າຍລົດ: row.car_number,
        ຊື່ບັດ: 'ບໍ່ມີຂໍ້ມູນ',
        // ປະເພດການຈ່າຍ: row.payment_type,
        ໄລຍະເວລາການເຊົ່າ: row.duration_rent,
        ລວມເປັນເງິນ: row.total_price,
        ແຂວງ: row.province,
        ເບີໂທຕິດຕໍ່: row.phone_number,
        ເວລາເຂົ້າ: moment(row.rent_start).format('DD/MM/YYYY HH:mm:ss'),
        ເວລາອອກ: moment(row.rent_end).format('DD/MM/YYYY HH:mm:ss'),
      };
    } else {
      return {
        ບໍລິສັດ: row.company?.company_name,
        ປ້າຍລົດ: row.car_number,
        ຊື່ບັດ: row.card.card_id,
        // ປະເພດການຈ່າຍ: row.payment_type,
        ໄລຍະເວລາການເຊົ່າ: row.duration_rent,
        ລວມເປັນເງິນ: row.total_price,
        ແຂວງ: row.province,
        ເບີໂທຕິດຕໍ່: row.phone_number,
        ເວລາເຂົ້າ: moment(row.rent_start).format('DD/MM/YYYY HH:mm:ss'),
        ເວລາອອກ: moment(row.rent_end).format('DD/MM/YYYY HH:mm:ss'),
      };
    }
  });

  const handleChange = async (event) => {
    await getBilling();
    setCom(event.target.value);
  };

  const handleExport = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    XLSX.utils.sheet_add_aoa(ws, [['ລວມເປັນເງິນທັງໝົດ ' + total]], {
      origin: -1,
    });

    XLSX.writeFile(wb, 'ລາຍງານສົ່ງລົດອອກ.xlsx');
  };

  const getBilling = async (com) => {
    try {
      // let arr = [];
      const resp = await axios.get(
        // `${domain}/bill/getrentout?company_name=${com}`
        `${domain}/bill/getrentout`
      );

      // for (let index = 0; index < resp.data.length; index++) {
      //   if (resp.data[index].company !== null) {
      //     arr.push(resp.data[index]);
      //   }
      // }
      // console.log('DATA -=', arr);
      setStartData(resp.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCompany = async () => {
    try {
      const resp = await axios.get(`${domain}/company/getall`);
      setCompany(resp.data);
    } catch (error) {
      console.log(error);
    }
  };

  const DeleteBilling = async (id) => {
    try {
      if (window.confirm('ທ່ານຕ້ອງການລົບໃບບິນນີ້ ຫຼື ບໍ່ ?')) {
        await axios
          .delete(`${domain}/bill/delete/${id}`)
          .then(() => {
            // addToast("ທ່ານລົບໃບບິນນີ້ສໍາເລັດ", {
            //   appearance: "success",
            // });
            // alert("ທ່ານລົບໃບບິນນີ້ສໍາເລັດ")
            history.go(0);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompany();
    getBilling();
  }, []);

  console.log('startData', startData[0]?.company?.company_name);

  useEffect(() => {
    let dataFilter = [];

    for (let index = 0; index < startData.length; index++) {
      if (
        moment(startData[index].rent_end) >= start &&
        moment(startData[index].rent_end) <= end
      ) {
        dataFilter.push(startData[index]);
      }
    }

    setStartData(dataFilter);
  }, [start, end]);

  useEffect(() => {
    getBilling();
  }, [com]);

  return (
    <div className="container-full relative h-screen">
      <Header />
      <Navbar />
      <div className="flex justify-center my-5">
        <div className="w-full md:w-9/12 mx-4 md:mx-0 flex gap-x-5 flex-col gap-y-2 justify-center relative">
          <div className="flex justify-between flex-col sm:mb-2">
            <h4 className="text-xl mb-2 font-semibold">ສົ່ງລົດອອກ</h4>
            <div className="flex justify-between flex-col lg:flex-row">
              <DateRangePicker
                onChange={handleChangeDate}
                value={[start, end]}
                locale={'th'}
                calendarIcon={<CalendarMonthIcon />}
                className="w-full text-center lg:w-10"
              />
              <FormControl className="flex justify-center sm:justify-end my-2 lg:my-0 text-sm text-white w-full lg:w-40 rounded-sm font-semibold mb-2 sm:mb-0">
                <InputLabel className="bg-white" id="demo-simple-select-label">
                  ເລືອກບໍລິສັດ
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={com}
                  label="age"
                  onChange={handleChange}
                >
                  <MenuItem value="">ທັງໝົດ</MenuItem>
                  {company.map((val, key) => {
                    return (
                      <MenuItem key={key} value={val.company_name}>
                        {val.company_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <button
                className="flex justify-center items-center lg:justify-end bg-[#ca1541] px-4 py-3 my-2 lg:my-0 text-sm text-white w-full lg:w-fit rounded-sm font-semibold mb-2 sm:mb-0"
                onClick={handleExport}
              >
                <DownloadIcon />
                ດາວໂຫລດລາຍງານ
              </button>
            </div>
          </div>
          <div className="w-full h-64 lg:h-[600px] shadow-screen">
            <DataGrid
              columns={columnsData}
              rows={startData}
              // loading={startData.length > 0 ? false : true}
              disableColumnMenu
              sx={{ border: 'none', fontSize: '16px' }}
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    ບໍ່ມິຂໍ້ມູນ
                  </Stack>
                ),
                Footer: CustomFooterTotalComponent,
              }}
              componentsProps={{
                footer: { total },
              }}
              onStateChange={(state) => {
                const total = startData
                  .map((item) => item.total_price)
                  .reduce((a, b) => a + b, 0);
                setTotal(total);
              }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OutcomePage;
