import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { domain } from '../../Variables/domain';

export default function ModalAddUser() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');

  const { addToast } = useToasts();

  const addCompany = () => {
    axios
      .post(`${domain}/auth/register/`, {
        name: name,
        username: username,
        password: password,
        role: role,
      })
      .then(() => {
        addToast('ເພີ່ມບໍລິສັດສໍາເລັດ', {
          appearance: 'success',
        });
      }).catch = (err) => {
      console.log(err);
      addToast(err, {
        appearance: 'error',
      });
    };
  };

  const handleChangeRole = (event) => {
    setRole(event.target.value);
    console.log(event.target.value);
  };

  return (
    <div>
      <button
        className="bg-[#26B899] rounded-sm shadow-sm px-4 py-2 text-lg text-white"
        onClick={handleOpen}
      >
        ເພີ່ມພະນັກງານ
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="modelCardUser">
          <label className="modalLabel">ຊື່ທີ່ສະແດງ</label>
          <input
            className="modalInput"
            type="text"
            placeholder="ໃສ່ຊື່ຜູ້ສະມັກ"
            required
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
          <label className="modalLabel">ຊື່ເຂົ້າໃຊ້ງານ</label>
          <input
            className="modalInput"
            type="text"
            placeholder="ໃສ່ຊື່ຜູ້ໃຊ້ງານ"
            required
            onChange={(event) => {
              setUsername(event.target.value);
            }}
          />
          <label className="modalLabel">ລະຫັດຜ່ານ</label>
          <input
            className="modalInput"
            type="text"
            placeholder="ໃສ່ລະຫັດຜ່ານ"
            required
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
          <label className="modalLabel">ເລືອກຕໍາແໜ່ງ</label>
          <FormControl className="dropdown-user">
            <InputLabel className="dropdown-label">ເລືອກຕໍາແໜ່ງ</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={role}
              label="age"
              onChange={handleChangeRole}
              required
            >
              <MenuItem value="admin">ຜູ້ດູແລ</MenuItem>
              <MenuItem value="emp">ພະນັກງານ</MenuItem>
            </Select>
          </FormControl>
          <button className="modalBtn" onClick={addCompany}>
            ເພີ່ມພະນັກງານ
          </button>
        </form>
      </Modal>
    </div>
  );
}
