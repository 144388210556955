import React, { useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Navbar from '../../Components/Navbar';
import ModalAddUser from '../../Components/User/ModalAddUser';
import { domain } from '../../Variables/domain';

const UserPage = () => {
  const history = useHistory();

  const [tableData, setTableData] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const deleteCard = async (id) => {
    if (window.confirm('ທ່ານຕ້ອງການລົບພະນັກງານຄົນນີ້ ຫຼື ບໍ່ ?')) {
      await axios.delete(`${domain}/auth/delete/${id}`).then((response) => {
        setEmployeeList(
          employeeList.filter((val) => {
            return val.id !== id;
          })
        );
        history.go(0);
      });
    }
  };

  const getSensor = async () => {
    try {
      const resp = await axios.get(`${domain}/auth/getall`);
      setTableData(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const updateUser = (id) => {
    window.location = '/user/' + id;
  };

  React.useEffect(() => {
    getSensor();

    const interval = setInterval(() => {
      getSensor();
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container-full relative h-screen">
      <Header />
      <Navbar />
      <div className="flex justify-center my-5">
        <div className="w-11/12 md:w-9/12 mx-4 md:mx-0 flex gap-x-5 flex-col gap-y-2 md:gap-y-0 justify-center">
          <div className="flex items-center justify-between mb-2">
            <h4 className="text-xl mb-2 font-semibold">ລາຍການພະນັກງານທັງໝົດ</h4>
            <ModalAddUser />
          </div>
          <div className="w-full shadow-sm h-[450px] sm:h-[600px] overflow-y-auto border rounded-sm">
            <table className="w-full text-center">
              <thead>
                <tr className="w-full">
                  <th className="w-auto bg-[#ed5626] p-4 text-white">
                    ຊື່ພະນັກງານ
                  </th>
                  <th className="w-auto bg-[#ed5626] p-4 text-white">
                    ຊື່ເຂົ້າໃຊ້ງານ
                  </th>
                  <th className="w-auto bg-[#ed5626] p-4 text-white">
                    ຕໍາແໜ່ງ
                  </th>
                  <th className="w-auto bg-[#ed5626] p-4 text-white">
                    ສ້າງເມື່ອ
                  </th>
                  <th className="w-auto bg-[#ed5626] p-4 text-white">
                    ແກ້ໄຂເມື່ອ
                  </th>
                  <th
                    className="w-auto bg-[#ed5626] p-4 text-white"
                    colSpan="2"
                  >
                    ການຈັດການ
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((val, key) => {
                  return (
                    <tr
                      role="row"
                      key={key}
                      style={{ borderBottom: '1px solid #e6e6e6' }}
                    >
                      <td className="px-4 py-4">{val.name}</td>
                      <td className="px-4 py-4">{val.username}</td>
                      <td className="px-4 py-4">
                        {val.role === 'admin' ? 'ຜູ້ດູແລ' : 'ພະນັກງານ'}
                      </td>
                      {/* <td className="px-4 py-4">{val.password}</td> */}
                      <td className="px-4 py-4">
                        {moment(val.rent_start).format('DD/MM/YYYY HH:mm:ss')}
                      </td>
                      <td className="px-4 py-4">
                        {moment(val.rent_end).format('DD/MM/YYYY HH:mm:ss')}
                      </td>
                      <td
                        key={val.id}
                        onClick={() => updateUser(val.id)}
                        className="px-4 py-4"
                      >
                        <button
                          key={val.id}
                          className="w-28 text-center bg-orange-300 border-orange-500 border-2 px-4 py-1 rounded-full"
                          onClick={handleOpen}
                        >
                          ແກ້ໄຂ
                        </button>
                      </td>
                      <td className="px-4 py-4">
                        <button
                          className="w-28 text-center bg-red-300 border-red-500 border-2 px-4 py-1 rounded-full"
                          onClick={() => {
                            deleteCard(val.id);
                          }}
                        >
                          ລົບ
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UserPage;
