import React, { useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import { domain } from '../../Variables/domain';

function RatePrice() {
  const [rate, setRate] = useState([]);

  const getSensor = async () => {
    try {
      const resp = await axios.get(`${domain}/rate/getall`);
      setRate(resp.data);
    } catch (error) {
      console.log(error);
    }
  };

  const dateTime = new Date().toLocaleString();

  const updateRate = (id) => {
    window.location = '/rates/' + id;
  };

  React.useEffect(() => {
    getSensor();

    const interval = setInterval(() => {
      getSensor();
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  if (rate === null) {
    return;
  }

  return (
    <div className="flex flex-row justify-between w-full md:w-9/12 mx-4 md:mx-0 items-center">
      <div className="">
        <span className="text-lg hidden sm:block">
          {moment(dateTime).format('DD/MM/YYYY HH:mm:ss')}
        </span>
      </div>
      <div className="w-full sm:w-fit md:mx-0 px-2 md:px-6 flex flex-row gap-x-5 items-center justify-between sm:justify-end bg-gray-100 py-2">
        <div className="">
          <span className="text-lg">{rate.rate ? rate.rate : '...'}ກີບ/ນາທີ</span>
        </div>
        <div>
          <button
            className="bg-[#26B899] rounded-sm px-4 py-2 text-white text-lg"
            onClick={() => updateRate(rate.id)}
          >
            ແກ້ໄຂເລດເງິນ
          </button>
        </div>
      </div>
    </div>
  );
}

export default RatePrice;
