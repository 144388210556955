import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { domain } from '../../Variables/domain';

export default function ModalAddCard() {
  const [open, setOpen] = useState(false);
  const [cardName, setCardName] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { addToast } = useToasts();

  const addCard = () => {
    axios
      .post(`${domain}/card/insert`, {
        card_id: cardName,
      })
      .then(() => {
        addToast('ເພີ່ມບັດສໍາເລັດ', {
          appearance: 'success',
        });
      }).catch = (err) => {
      console.log(err);
      addToast(err, {
        appearance: 'error',
      });
    };
  };

  return (
    <div>
      <button
        className="bg-[#26B899] rounded-sm shadow-sm px-4 py-2 text-lg text-white"
        onClick={handleOpen}
      >
        ເພີ່ມບັດ
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className="modelCard">
          <label className="modalLabel">ຊື່ບັດ</label>
          <input
            className="modalInput"
            type="text"
            placeholder="ໃສ່ຊື່ບັດ"
            required
            onChange={(event) => {
              setCardName(event.target.value);
            }}
          />
          <button className="modalBtn" onClick={addCard}>
            ເພີ່ມບັດ
          </button>
        </form>
      </Modal>
    </div>
  );
}
