import React from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';

export default function ProfileControl() {
  const history = useHistory();

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('Profile');
    history.replace('/');
  };

  const token = JSON.parse(localStorage.getItem('token'));

  //Dashboard Btn
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!token) {
    console.log('Token is Missing');
    return;
  }

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="flex bg-white rounded-full"
      >
        <Avatar className="w-8 h-8 mr-0 sm:mr-2 text-sm bg-[#1d3a8a] flex items-center">
          <PersonIcon />
        </Avatar>
        <span className="text-black hidden sm:block">
          {token?.name ? token.name : 'ບໍ່ມີຂໍ້ມູນ'}
        </span>
      </Button>
      <Menu
        id="basic-menu"
        className="w-full"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem className="profile-name">
          {token?.name ? token.name : 'ບໍ່ມີຂໍ້ມູນ'}
        </MenuItem>
        <MenuItem onClick={logout}>ອອກຈາກລະບົບ</MenuItem>
      </Menu>
    </>
  );
}
