import React, { useState, useEffect } from 'react';
import Link from '@mui/material/Link';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { useParams, useHistory } from 'react-router-dom';
import { domain } from '../../Variables/domain';

export default function EditCard() {
  const history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(`${domain}/card/get/` + id);
      setCardName(resp.data.card_id);
    };

    // call the function
    fetchData();
  }, [id]);

  const { addToast } = useToasts();

  const updateCard = async (id) => {
    try {
      await addToast('ແກ້ໄຂບັດສໍາເລັດ', {
        appearance: 'success',
      });
      history.replace('/dashboard/cardlist');
      await axios.patch(`${domain}/card/update/` + id, { card_id: cardName });
      history.go(0);
    } catch (error) {
      addToast('ຊື່ນີ້ມີການໃຊ້ງານແລ້ວ', {
        appearance: 'error',
      });
    }
  };

  const [cardName, setCardName] = useState('');

  return (
    <div className="flex justify-center">
      <form className="modelCard py-4 h-fit" action="">
        <Link
          href="/dashboard/cardlist"
          className="w-full bg-[#ca1541] shadow-sm mb-8 text-white px-4 py-2 text-lg no-underline font-semibold rounded-sm text-center"
        >
          ຍົກເລິກການແກ້ໄຂ
        </Link>
        <label className="modalLabel mb-2">ຊື່ບັດ</label>
        <input
          className="modalInput mb-2"
          type="text"
          defaultValue={cardName}
          placeholder="ໃສ່ຊື່ບັດ"
          onChange={(event) => {
            setCardName(event.target.value);
          }}
        />
        <button className="modalBtn rounded-sm" onClick={() => updateCard(id)}>
          ແກ້ໄຂຊື່ບັດ
        </button>
      </form>
    </div>
  );
}
