import React, { useState } from 'react';
import axios from 'axios';
import { domain } from '../Variables/domain';
import CircularProgress from '@mui/material/CircularProgress';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

function ReportCard() {
  const today = moment().startOf('day');

  const [data, setData] = React.useState();

  let Today = today.toDate().toISOString();
  let EndToday = moment(today).endOf('day').toDate().toISOString();

  const date1 = new Date(Today);
  const date2 = new Date(EndToday);

  const [value, onChange] = useState([date1, date2]);

  const getSensor = async (value) => {
    try {
      const resp = await axios.get(
        `${domain}/bill/getFilter?start=${value[0]}&end=${value[1]}`
      );
      setData(resp.data.Report);
    } catch (error) {
      console.log(error);
    }
  };

  // console.log('DATA =', data);

  React.useEffect(() => {
    getSensor(value);

    const interval = setInterval(() => {
      getSensor();
    }, 300000);

    return () => clearInterval(interval);
  }, [value]);

  if (data === null) {
    return;
  }

  let dataTotal;
  if (data?.TotalPrice[0]?.totals_price === undefined) {
    dataTotal = (
      <span className="flex items-center justify-center text-black text-4xl md:text-5xl h-full">
        0 ກີບ
      </span>
    );
  } else {
    dataTotal = (
      <span className="flex items-center justify-center text-black text-4xl md:text-5xl h-full">
        {(
          Math.round(data.TotalPrice[0].totals_price * 100) / 100
        ).toLocaleString()}{' '}
        ກີບ
      </span>
    );
  }

  return (
    <div className="w-full md:w-9/12 mx-4 md:mx-0 bg-gray-100 px-2 md:px-6 py-4 md:py-8 shadow-sm rounded-sm border">
      <div className="flex justify-between flex-col sm:flex-row mb-5 items-center gap-y-3 sm:gap-y-0">
        <span className="text-xl font-semibold">ລາຍງານໂດຍລວມ</span>
        <DateRangePicker
          onChange={onChange}
          value={value}
          locale={'th'}
          calendarIcon={<CalendarMonthIcon />}
          className="w-full text-center sm:w-fit"
        />
      </div>
      <div className="md:columns-3 flex flex-col md:flex-row justify-center gap-x-5 gap-y-4 md:gap-y-0">
        <div className="bg-white rounded-sm shadow-sm w-full h-48 flex flex-col justify-center items-center">
          <h4 className="flex rounded-t-sm items-center justify-center w-full text-center font-semibold bg-[#ca1541] text-white text-xl h-20">
            ລາຍຮັບທັງໝົດ
          </h4>
          {dataTotal}
        </div>
        <div className="bg-white rounded-sm shadow-sm w-full h-48 flex flex-col justify-center items-center">
          <h4 className="flex items-center rounded-t-sm justify-center w-full text-center font-semibold bg-[#26B899] text-white text-xl h-20">
            ຮັບລົດເຂົ້າ
          </h4>
          <span className="flex items-center justify-center text-black text-4xl md:text-5xl h-full">
            {data ? data.RentIn.length : <CircularProgress />}
          </span>
        </div>
        <div className="bg-white rounded-sm shadow-sm w-full h-48 flex flex-col justify-center items-center">
          <h4 className="flex items-center rounded-t-sm justify-center w-full text-center font-semibold bg-[#ed5626] text-white text-xl h-20">
            ສົ່ງລົດອອກ
          </h4>
          <span className="flex items-center justify-center text-black text-4xl md:text-5xl h-full">
            {data ? data.RentOut.length : <CircularProgress />}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ReportCard;
