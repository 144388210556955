import React from 'react';

const Footer = () => {
  return (
    <div className="text-center text-gray-500 hidden sm:block">
      Powered by ReTech Sole Co.,Ltd
    </div>
  );
};

export default Footer;
