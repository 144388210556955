import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import Overviews from './Pages/Admin/Overviews';
import IncomePage from './Pages/Admin/IncomePage';
import OutcomePage from './Pages/Admin/OutcomePage';
import CardPage from './Pages/Admin/CardPage';
import EditCard from './Components/Card/EditCard';
import PrivateRoute from './Guard/auth';
import Login from './Pages/Login';
import EditRate from './Components/Rate/EditRate';
import EditCompany from './Components/Company/EditCompany';
import CompanyPage from './Pages/Admin/CompanyPage';
import UserPage from './Pages/Admin/UserPage';
import EditUser from './Components/User/EditUser';

function App() {
  return (
    <>
      <ToastProvider
        autoDismiss
        autoDismissTimeout={5000}
        placement="top-right"
      >
        <Router>
          <Switch>
            <PrivateRoute path="/dashboard/overview">
              <Overviews />
            </PrivateRoute>
            <PrivateRoute path="/dashboard/income">
              <IncomePage />
            </PrivateRoute>
            <PrivateRoute path="/dashboard/outcome">
              <OutcomePage />
            </PrivateRoute>
            <PrivateRoute path="/dashboard/cardlist">
              <CardPage />
            </PrivateRoute>
            <PrivateRoute path="/dashboard/company">
              <CompanyPage />
            </PrivateRoute>
            <PrivateRoute path="/dashboard/user">
              <UserPage />
            </PrivateRoute>
            <Route path="/" exact>
              <Login />
            </Route>
            <Route path="/card/:id">
              <EditCard />
            </Route>
            <Route path="/rates/:id">
              <EditRate />
            </Route>
            <Route path="/company/:id">
              <EditCompany />
            </Route>
            <Route path="/user/:id">
              <EditUser />
            </Route>
          </Switch>
        </Router>
      </ToastProvider>
    </>
  );
}

export default App;
