import React, { useState } from 'react';
import { ToastProvider } from 'react-toast-notifications';
import moment from 'moment';
import axios from 'axios';
import ModalCard from '../../Components/Card/ModalAddCard';
import { useHistory } from 'react-router-dom';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Navbar from '../../Components/Navbar';
import { domain } from '../../Variables/domain';

const CardPage = () => {
  const history = useHistory();

  const [tableData, setTableData] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const deleteCard = async (id) => {
    if (window.confirm('ທ່ານຕ້ອງການລົບບັດນີ້ ຫຼື ບໍ່ ?')) {
      await axios.delete(`${domain}/card/delete/${id}`).then((response) => {
        setEmployeeList(
          employeeList.filter((val) => {
            return val.id !== id;
          })
        );
        history.go(0);
      });
    }
  };

  const getSensor = async () => {
    try {
      const resp = await axios.get(`${domain}/card/getall`);
      setTableData(resp.data);
    } catch (error) {
      console.log(error);
    }
  };

  const updateCard = (id) => {
    window.location = '/card/' + id;
  };

  React.useEffect(() => {
    getSensor();

    const interval = setInterval(() => {
      getSensor();
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  return (
    <ToastProvider autoDismiss autoDismissTimeout={5000} placement="top-right">
      <div className="container-full relative h-screen">
        <Header />
        <Navbar />
        <div className="flex justify-center my-5">
          <div className="w-11/12 md:w-9/12 mx-4 md:mx-0 flex gap-x-5 flex-col gap-y-2 md:gap-y-0 justify-center">
            <div className="flex items-center justify-between mb-2">
              <h4 className="text-xl mb-2 font-semibold">ລາຍການບັດທັງໝົດ</h4>
              <ModalCard />
            </div>
            <div className="w-full shadow-sm h-[450px] sm:h-[600px] overflow-y-auto border rounded-sm">
              <table className="w-full text-center">
                <thead>
                  <tr className="w-full">
                    <th className="w-fit bg-[#26B899] p-4 text-white">
                      ສະຖານະ
                    </th>
                    <th className="w-fit bg-[#26B899] p-4 text-white">
                      ຊື່ບັດ
                    </th>
                    <th className="w-fit bg-[#26B899] p-4 text-white">
                      ສ້າງເມື່ອ
                    </th>
                    <th className="w-fit bg-[#26B899] p-4 text-white">
                      ແກ້ໄຂເມື່ອ
                    </th>
                    <th
                      className="w-fit bg-[#26B899] p-4 text-white"
                      colSpan="2"
                    >
                      ການຈັດການ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((val, key) => {
                    return (
                      <tr
                        role="row"
                        key={key}
                        style={{ borderBottom: '1px solid #e6e6e6' }}
                      >
                        <td className="px-4 py-4 flex justify-center">
                          {val.card_status ? (
                            <div className="w-32 text-center bg-orange-100 border-[#ed5626] border-2 px-4 py-1 rounded-full">
                              ມີການໃຊ້ງານຢູ່
                            </div>
                          ) : (
                            <div className="w-32 text-center bg-green-100 border-green-600 border-2 px-4 py-1 rounded-full">
                              ວ່າງ
                            </div>
                          )}
                        </td>
                        <td className="px-4 py-4">{val.card_id}</td>
                        <td className="px-4 py-4">
                          {moment(val.rent_start).format('DD/MM/YYYY HH:mm:ss')}
                        </td>
                        <td className="px-4 py-4">
                          {moment(val.rent_end).format('DD/MM/YYYY HH:mm:ss')}
                        </td>
                        <td
                          key={val.id}
                          onClick={() => updateCard(val.id)}
                          className="px-4 py-4"
                        >
                          <button
                            key={val.id}
                            className="w-28 text-center bg-orange-300 border-orange-500 border-2 px-4 py-1 rounded-full"
                            onClick={handleOpen}
                          >
                            ແກ້ໄຂບັດ
                          </button>
                        </td>
                        <td className="px-4 py-4">
                          <button
                            className="w-28 text-center bg-red-300 border-red-500 border-2 px-4 py-1 rounded-full"
                            onClick={() => {
                              deleteCard(val.id);
                            }}
                          >
                            ລົບ
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </ToastProvider>
  );
};

export default CardPage;
