import React, { useState, useEffect } from 'react';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Navbar from '../../Components/Navbar';
import moment from 'moment';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DownloadIcon from '@mui/icons-material/Download';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import * as XLSX from 'xlsx';
import { domain } from '../../Variables/domain';

const IncomePage = () => {
  const [startData, setStartData] = useState([]);
  // const [start, setStart] = useState(moment().startOf('month'));
  // const [end, setEnd] = useState(moment().endOf('month'));
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  const handleChangeDate = async (e) => {
    await getBilling();
    setStart(e[0]);
    setEnd(e[1]);
  };

  const columnsData = [
    // { field: 'id', headerName: 'ID', width: 0, hidden: 'true'},
    {
      field: 'company',
      headerName: 'ຊື່ບໍລິສັດ',
      headerClassName: 'bg-[#26B899] text-white',
      width: 235,
      valueGetter: function (params) {
        if (params.row.company?.company_name === undefined) {
          return 'ບໍ່ມີຂໍ້ມູນ';
        } else {
          return params.row.company.company_name;
        }
      },
    },
    {
      field: 'car_number',
      headerName: 'ປ້າຍລົດ',
      headerClassName: 'bg-[#26B899] text-white',
      width: 230,
    },
    {
      field: 'card',
      headerName: 'ຊື່ບັດ',
      headerClassName: 'bg-[#26B899] text-white',
      width: 215,
      valueGetter: function (params) {
        if (params.row.card?.card_id === undefined) {
          return 'ບໍ່ມີຂໍ້ມູນ';
        } else {
          return params.row.card.card_id;
        }
      },
    },
    {
      field: 'province',
      headerName: 'ແຂວງ',
      headerClassName: 'bg-[#26B899] text-white',
      width: 280,
    },
    {
      field: 'phone_number',
      headerName: 'ເບີໂທຕິດຕໍ່',
      headerClassName: 'bg-[#26B899] text-white',
      width: 230,
      valueGetter: function (params) {
        if (params.row.phone_number === '') {
          return 'ບໍ່ມີຂໍ້ມູນ';
        } else {
          return params.row.phone_number;
        }
      },
    },
    {
      field: 'rent_start',
      headerName: 'ເວລາເຂົ້າ',
      headerClassName: 'bg-[#26B899] text-white',
      width: 250,
      valueGetter: (params) =>
        moment(params.row.rent_start).format('DD/MM/YYYY HH:mm:ss'),
    },
  ];

  const rows = startData.map((row) => {
    if (
      row.card?.card_id === undefined ||
      row.company?.company_name === undefined
    ) {
      return {
        ບໍລິສັດ: 'ບໍ່ມີຂໍ້ມູນ',
        ປ້າຍລົດ: row.car_number,
        ຊື່ບັດ: 'ບໍ່ມີຂໍ້ມູນ',
        ແຂວງ: row.province,
        ເບີໂທຕິດຕໍ່: row.phone_number,
        ເວລາເຂົ້າ: moment(row.rent_start).format('DD/MM/YYYY HH:mm:ss'),
      };
    } else {
      return {
        ບໍລິສັດ: row.company?.company_name,
        ປ້າຍລົດ: row.car_number,
        ຊື່ບັດ: row.card.card_id,
        ແຂວງ: row.province,
        ເບີໂທຕິດຕໍ່: row.phone_number,
        ເວລາເຂົ້າ: moment(row.rent_start).format('DD/MM/YYYY HH:mm:ss'),
      };
    }
  });

  const handleExport = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');

    XLSX.writeFile(wb, 'ລາຍງານຮັບລົດເຂົ້າ.xlsx');
  };

  const getBilling = async () => {
    try {
      const resp = await axios.get(`${domain}/bill/getrentin`);
      setStartData(resp.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBilling();
  }, []);

  useEffect(() => {
    let dataFilter = [];

    for (let index = 0; index < startData.length; index++) {
      if (
        moment(startData[index].rent_start) >= start &&
        moment(startData[index].rent_start) <= end
      ) {
        dataFilter.push(startData[index]);
      }
    }

    setStartData(dataFilter);
  }, [start, end]);

  return (
    <div className="container-full relative h-screen">
      <Header />
      <Navbar />
      <div className="flex justify-center my-5">
        <div className="w-full md:w-9/12 mx-4 md:mx-0 flex gap-x-5 flex-col gap-y-2 md:gap-y-0 justify-center relative">
          <div className="flex justify-between flex-col sm:mb-2">
            <h4 className="text-xl mb-2 font-semibold">ຮັບລົດເຂົ້າ</h4>
            <div className="flex justify-between flex-col sm:flex-row">
              <DateRangePicker
                onChange={handleChangeDate}
                value={[start, end]}
                locale={'th'}
                calendarIcon={<CalendarMonthIcon />}
                className="w-full text-center sm:w-10"
              />
              <button
                className="flex justify-center sm:justify-end bg-[#ca1541] px-4 py-3 my-2 sm:my-0 text-sm text-white w-full sm:w-fit rounded-sm font-semibold mb-2 sm:mb-0"
                onClick={handleExport}
              >
                <DownloadIcon />
                ດາວໂຫລດລາຍງານ
              </button>
            </div>
          </div>
          <div className="w-full h-96 lg:h-[600px] shadow-screen">
            <DataGrid
              columns={columnsData}
              rows={startData}
              // loading={tableData.length > 0 ? false : true}
              disableColumnMenu
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    ບໍ່ມິຂໍ້ມູນ
                  </Stack>
                ),
              }}
              sx={{ border: 'none', fontSize: '16px' }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default IncomePage;
