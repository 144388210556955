import React from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

const Menus = [
  { title: 'ພາບລວມ', icon: 'overviews', url: '/dashboard/overview' },
  { title: 'ຮັບລົດເຂົ້າ', icon: 'rent-in', url: '/dashboard/income' },
  { title: 'ສົ່ງລົດອອກ', icon: 'rent-out', url: '/dashboard/outcome' },
  { title: 'ລາຍການບັດ', icon: 'card', url: '/dashboard/cardlist' },
  { title: 'ລາຍການບໍລິສັດ', icon: 'company', url: '/dashboard/company' },
  { title: 'ຈັດການຜູ້ໃຊ້', icon: 'user', url: '/dashboard/user' },
];

function Navbar() {
  return (
    <div className="bg-[#26B899] h-20 flex justify-center items-center border-t fixed bottom-0 left-0 right-0 z-50 sm:static">
      <div className="w-full flex justify-evenly sm:justify-center gap-x-4 overflow-x-auto">
        {Menus.map((Menu, index) => (
          <NavLink
            to={Menu.url}
            key={index}
            className={`text-gray-500 sm:text-white transition pb-px ease-in-out duration-200 flex flex-col items-center`}
            activeStyle={{ borderBottom: '4px solid white', color: '#fff' }}
          >
            <img
              src={require(`../assets/icons/${Menu.icon}.svg`)}
              alt={`${Menu.icon}`}
              className="w-8 block sm:hidden"
            />
            <span className="text-lg hidden sm:block">{Menu.title}</span>
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export default Navbar;
