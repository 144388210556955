import React, { useState, useEffect } from 'react';
import Link from '@mui/material/Link';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { useParams, useHistory } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { domain } from '../../Variables/domain';

export default function EditCompany() {
  const history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(`${domain}/auth/get/` + id);
      setName(resp.data.name);
      setUsername(resp.data.username);
      setRole(resp.data.role);
    };

    // call the function
    fetchData();
  }, [id]);

  const { addToast } = useToasts();

  const updateCompany = async (id) => {
    try {
      history.replace('/dashboard/user');
      await axios.patch(`${domain}/auth/update/` + id, {
        name: name,
        password: password,
        role: role,
      });
      addToast('ເພີ່ມບັດສໍາເລັດ', {
        appearance: 'success',
      });
      history.go(0);
    } catch (error) {
      addToast('ຊື່ເຂົ້າໃຊ້ງານນີ້ມີການໃຊ້ງານແລ້ວ', {
        appearance: 'error',
      });
    }
  };

  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [password, setPassword] = useState('');

  const handleChangeRole = (event) => {
    setRole(event.target.value);
    console.log(event.target.value);
  };

  return (
    <div className="flex justify-center">
      <form className="modelCard py-4 h-fit" action="">
        <Link
          href="/dashboard/user"
          className="w-full bg-[#ca1541] shadow-sm mb-8 text-white px-4 py-2 text-lg no-underline font-semibold rounded-sm text-center"
        >
          ຍົກເລິກການແກ້ໄຂ
        </Link>
        <label className="modalLabel">ຊື່ເຂົ້າໃຊ້ງານ (Username)</label>
        <input
          className="modalInput bg-slate-300 select-none outline-0"
          type="text"
          defaultValue={username}
          placeholder="ໃສ່ຊື່ຜູ້ສະມັກ"
          readOnly
        />
        <label className="modalLabel">ຊື່ທີ່ສະແດງ (Fullname)</label>
        <input
          className="modalInput"
          type="text"
          defaultValue={name}
          placeholder="ໃສ່ຊື່ຜູ້ສະມັກ"
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        {/* <label className="modalLabel">ເລືອກຕໍາແໜ່ງ</label> */}
        <FormControl className="dropdown-user">
          <InputLabel className="dropdown-label">
            ເລືອກຕໍາແໜ່ງ (Role)
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={role}
            label="age"
            onChange={handleChangeRole}
          >
            <MenuItem value="admin">ຜູ້ດູແລ</MenuItem>
            <MenuItem value="emp">ພະນັກງານ</MenuItem>
          </Select>
        </FormControl>
        <hr className="my-2 h-0 border-2 border-[#26B899]" />
        <label className="modalLabel">
          ຫາກຕ້ອງການປ່ຽນລະຫັດຜ່ານໃໝ່ ກະລຸນາກອກ
        </label>
        <input
          className="modalInput"
          type="text"
          placeholder="ລະຫັດຜ່ານໃໝ່"
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        />
        <button
          className="modalBtn rounded-sm"
          onClick={() => updateCompany(id)}
        >
          ແກ້ໄຂພະນັກງານ
        </button>
      </form>
    </div>
  );
}
