import React, { useState, useEffect } from 'react';
import Link from '@mui/material/Link';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { useParams, useHistory } from 'react-router-dom';
import { domain } from '../../Variables/domain';

export default function EditCompany() {
  const history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(`${domain}/company/get/` + id);
      setCompanyName(resp.data.company_name);
    };

    // call the function
    fetchData();
  }, [id]);

  const { addToast } = useToasts();

  const updateCompany = async (id) => {
    try {
      history.replace('/dashboard/company');
      await axios.patch(`${domain}/company/update/` + id, {
        company_name: companyName,
      });
      addToast('ເພີ່ມບັດສໍາເລັດ', {
        appearance: 'success',
      });
      history.go(0);
    } catch (error) {
      addToast('ຊື່ນີ້ມີການໃຊ້ງານແລ້ວ', {
        appearance: 'error',
      });
    }
  };

  const [companyName, setCompanyName] = useState('');

  return (
    <div className="flex justify-center">
      <form className="modelCard py-4 h-fit" action="">
        <Link
          href="/dashboard/company"
          className="w-full bg-[#ca1541] shadow-sm mb-8 text-white px-4 py-2 text-lg no-underline font-semibold rounded-sm text-center"
        >
          ຍົກເລິກການແກ້ໄຂ
        </Link>
        <label className="modalLabel mb-2">ຊື່ບໍລິສັດ</label>
        <input
          className="modalInput mb-2"
          type="text"
          defaultValue={companyName}
          placeholder="ໃສ່ຊື່ບໍລິສັດ"
          onChange={(event) => {
            setCompanyName(event.target.value);
          }}
        />
        <button
          className="modalBtn rounded-sm"
          onClick={() => updateCompany(id)}
        >
          ແກ້ໄຂຊື່ບໍລິສັດ
        </button>
      </form>
    </div>
  );
}
