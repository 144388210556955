import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './index.css';
import App from './App';
import { ToastProvider } from 'react-toast-notifications';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <ToastProvider autoDismiss autoDismissTimeout={5000} placement="top-right">
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ToastProvider>
  </>
);
