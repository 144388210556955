import React from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import ProfileControl from './ProfileControl';

function Header() {
  return (
    <div className="w-full py-5 md:py-8 bg-[#26B899] font-semibold text-white flex justify-center align-middle drop-shadow-sm relative">
      <div className="w-9/12 flex justify-between items-center relative">
        <div></div>
        <NavLink
          to="/dashboard/overview"
          className="logoBrand text-2xl flex flex-col items-center"
        >
          <img
            src={require('../assets/img/logo_rent.png')}
            className="bg-white rounded-sm"
            width="60"
            alt="logo"
          />
          <span className="hidden md:block">ລະບົບຕິດຕາມການສາກໄຟ</span>
        </NavLink>
        <ProfileControl />
      </div>
    </div>
  );
}

export default Header;
