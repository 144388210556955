import React from 'react';
import BillIndex from '../../Components/Billing/BillIndex';
import Card from '../../Components/Card/Card';
import Company from '../../Components/Company/Company';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import Navbar from '../../Components/Navbar';
import RatePrice from '../../Components/Rate/RatePrice';
import ReportCard from '../../Components/ReportCard';
import User from '../../Components/User/User';

const Overviews = () => {
  return (
    <div className="container-full relative h-full">
      <Header />
      <Navbar />
      <div className="flex flex-col justify-center">
        <div className="pt-3 flex justify-center">
          <RatePrice />
        </div>
        <div className="my-5 flex justify-center">
          <ReportCard />
        </div>
        <div className="my-5 flex justify-center flex-row">
          <BillIndex />
        </div>
        <div className="my-5 flex justify-center mr-[30px] sm:mr-0">
          <div className="w-full md:w-9/12 mx-4 md:mx-0 flex gap-x-5 flex-col md:flex-row gap-y-8 md:gap-y-0">
            <Card />
            <Company />
          </div>
        </div>
        <div className="mt-5 mb-12 flex justify-center flex-row">
          <User />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Overviews;
